import React from 'react'
import { useLocalStorage, useSearchParam } from 'react-use'
import { navigate } from 'gatsby'

import Box from '../../components/Box'
import Text from '../../components/Text'
import Flex from '../../components/Flex'
import Button from '../../components/Button'
import Container from '../../components/Container'
import BackgroundImage from '../../components/BackgroundImage'
import { responsive } from '../../contexts/responsive'

import success from './success.svg'
import { Image } from '@chakra-ui/react'
import Link from '../../components/Link'

// const buttons = [
//   { text: '我想訂閱', to: '/contact/subscribed', trackerName: 'collab_subscribe' },
//   { text: '不需要，謝謝', to: '/contact/later', trackerName: 'collab_unsubscribe' },
// ]

const SuccessPage = () => {
  const qsEmail = useSearchParam('email')
  const [value] = useLocalStorage('email', qsEmail)
  if (!value && typeof window !== 'undefined') {
    navigate('/contact')
    return null
  }
  return (
    <Container
      pt={responsive('2.5em', '6em')}
      pb={responsive('4em', '8.75em')}
      textAlign={'center'}
      maxWidth={responsive('38em', '46.75em')}
      px={responsive('4em', 0)}
    >
      <Box mx="auto" width={responsive('3.25em', '9.7%')}>
        <BackgroundImage src={success} ratio={1} />
      </Box>
      <Text.Bold
        mt={responsive('0.625rem', '0.25em')}
        fontSize={responsive('1.75em', '3.75em')}
      >
        成功送出
      </Text.Bold>
      <Text
        mt={responsive('0.75rem', '0.67em')}
        color="custom.textGray"
        fontSize={responsive('0.875em', '1.875em')}
      >
        我們已同時將填答紀錄寄至你的信箱
        <br />
        2 個工作天內將有專人回覆
        <br />
        若真的等不及了，
        <Box as="br" display={responsive('inline', 'none')} />
        可以撥打專線 02-2741-6989
      </Text>
      <Box
        textAlign={'justify'}
        fontSize={responsive('0.875em', '1.875em')}
        mt={responsive('1rem', '1.875em')}
        pt={responsive('1rem', '2.5em')}
        borderTop={responsive('1px solid', '2.5px solid')}
        borderColor="custom.borderGray"
      >
        <Text>
          光是發出合作詢問，對 RE:LAB 就已經是很大的鼓勵！
          <br />
          <br />
          為了感謝你的肯定，我們準備了一份禮物要送給你——
          <b>
            「超開放實驗室 ｜ 問卷 X 心理測驗 X 互動遊戲｜快速產生器」回饋抵用券
          </b>
          ，<Text.Hightlight>記得收信領取優惠代碼！</Text.Hightlight>
          <br />
          <br />
          <Link href="https://url.relab.cc/231102_relabweb_official_bn">
            <Image
              src="https://r2.relab.cc/RELAB%20%E5%AE%98%E7%B6%B2%E8%A9%A2%E5%95%8F%E5%9B%9E%E8%A6%86%E4%BF%A1%E4%BB%B6-01.png"
              alt="OOOPEN Lab"
            />
          </Link>
          <br />
          這是 RE:LAB 2023
          年最新開發的線上工具，讓不會程式的行銷夥伴也能快速產出有趣的「互動問卷/測驗/遊戲」，高度客製化的視覺與流暢的互動體驗大獲好評，操作簡單又直覺，邀請你免費註冊來玩玩看！
        </Text>
      </Box>
      <Flex mt={responsive('2em', '3.5em')} justifyContent={'center'}>
        <Button.Outline
          fontSize={responsive('0.9375em', '2em')}
          py={responsive('0.375em', '0.42em')}
          trackerName="collab_ooopenlab"
          href="https://url.relab.cc/231102_relabweb_official_bt"
          isExternal
        >
          免費註冊體驗製作
        </Button.Outline>
      </Flex>
    </Container>
  )
}

export default SuccessPage
